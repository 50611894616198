.ffEditPick {
    width: 100%;
    margin-bottom: 24px;
    h2 {
        color: #222;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 100%;
        margin-bottom: 12px;
    }
    .ffEditPickBox {
        border-radius: 8px;
        border: 1px solid #EFEFEF;
        background: #FFF;
        box-shadow: 0px 0px 8px 0px rgba(34, 34, 34, 0.10);
        width: 100%;
        overflow: hidden;
        margin-bottom: 12px;
        .ffEditPickPic {
            width: 100%;
            height: auto;
            background: #fff;
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .ffEditPickDesc {
            width: 100%;
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            h3 {
                color: #222;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                width: 100%;
            }
            label {
                color: #595959;
                text-align: left;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}
.ffMainContent {
    &.darkTheme {
        .ffEditPick {
            h2 {
                color: #fff;
            }
            .ffEditPickBox {
                background: #731546;
                border: #731546 solid 1px;
                .ffEditPickPic {
                    background: #731546;
                    &.ffCompetition {
                        background: #fff;
                    }
                }
                .ffEditPickDesc {
                    h3 {
                        color: #fff;
                    }
                }
            }
        }
    }
}