.ffAboutContent {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #EFEFEF;
  background: #FFF;
  box-shadow: 0px 0px 8px 0px rgba(34, 34, 34, 0.10);
  .ffAboutHead {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    gap: 13px;
    border-bottom: #E8E8E8 solid 1px; 
    margin-bottom: 16px;
    h1 {
      color: #222;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .ffAboutDesc {
    width: 100%;
    padding: 20px;
    @media (max-width:991px) {
      padding: 10px;
    }
    p {
      color: #222;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 156%;
      margin-bottom: 20px;
    }
    h2 {
      color: #222;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 10px;
    }
    ul {
      margin-left: 14px;
      margin-bottom: 30px;
      li {
        list-style: disc;
        margin-bottom: 5px;
        span {
          color: #222;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 156%;
        }
      }
    }
  }
} 
.ffMainContent {
  &.darkTheme {
    .ffAboutContent {
      background: #731546;
      border: #731546 solid 1px;
      .ffAboutHead {
        border-bottom: #571035 solid 1px;
        h1 {
          color: #FFF;
        }
      }
      .ffAboutDesc {
        p {
          color: #fff;
        }
        h2 {
          color: #fff;
        }
        ul {
          li {
            color: #fff;
            span {
              color: #fff;
            }
          }
        }
      }
    }
  }
}